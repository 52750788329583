import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../change-start-date/DatePickerCustomStyles.css";
import { BiCalendar } from "react-icons/bi";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import moment from "moment";
import Axios from "../../../service/Axios";

const ChangeStartDate = () => {
  const [searchedUser, setSearchedUser] = useState("+62");
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });
  const [userFound, setUserFound] = useState(false);
  const [phone, setPhone] = useState("+62");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [newStartDate, setNewStartDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    setShowAlert({ type: "", message: "" });
    setUserFound(false);

    try {
      const url = `/v1/users/query/${searchedUser}`;
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { phone, name, startDate } = response.data;
        setPhone(phone);
        setShowAlert({
          type: "success",
          message: "User Found.",
          data: `${name} [ Start Date on: ${moment(startDate).format(
            "YYYY-MM-DD"
          )} ]`,
        });
        setIsLocked(!isLocked);
        setUserFound(true);
      } else {
        setShowAlert({
          type: "error",
          message: "User not found.",
        });
        setUserFound(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error 
        || (error.request ? "No response received from server." 
        : error.message || "An error occurred.");

      setShowAlert({
        type: "error",
        message: errorMessage,
      });
      setUserFound(false);
    }
  };

  const handleUnlock = () => {
    setIsLocked(false);
  };

  const handleStartDateChange = async (date) => {
    if (!date) {
      setShowAlert({
        type: "error",
        message: "Please select a new start date.",
      });
      return;
    }

    setIsSubmitting(true);

    // Format date using moment.js
    const startDateString = moment(date).format("YYYY-MM-DD");

    try {
      if (userFound) {
        const response = await Axios.put(
          `/v1/update-startdate/${phone}`,
          {
            newStartDate: startDateString,
          },
        );
        if (response.status === 200) {
          setShowAlert({
            type: "success",
            message: "Start date updated successfully.",
          });
          setSearchedUser("+62");
          setNewStartDate(null);
          setIsLocked(false);
        } else {
          setShowAlert({ type: "error", message: "Something went wrong" });
        }
      } else {
        setShowAlert({ type: "error", message: "User not found." });
      }
    } catch (error) {
      setShowAlert({
        type: "error",
        message: "An error occurred. Please ensure that the input is correct and that the rules are followed.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const changeColorOnHover = isLocked || isLoading ? "" : "hover:bg-yellow-600";

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {
        setShowAlert({ type: "", message: "" });
      }, 20000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  return (
    <div className="container mx-auto px-8">
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg border border-gray-100 p-8 mt-0 mb-56">
        {/* Phone Member Input */}
        <div className="mb-4">
          <label 
            htmlFor="phone" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Phone Number
          </label>
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
            <input
              type="text"
              id="phone"
              className="flex-grow px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
              placeholder="+62 Phone Number"
              value={searchedUser}
              onChange={(e) => setSearchedUser(e.target.value)}
              disabled={isLocked || isSubmitting}
            />
            <button
              onClick={userFound && isLocked ? handleUnlock : handleSearch}
              className={`w-full sm:w-auto px-6 py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isSubmitting}
            >
              {isLocked ? "Unlock" : "Search"}
            </button>
          </div>
        </div>

        {/* New Start Date Input */}
        <div className="mb-4">
          <label 
            htmlFor="newStartDate" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            New Start Date
          </label>
          <div className="relative">
            <DatePicker
              selected={newStartDate}
              onChange={(date) => setNewStartDate(date)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
              dateFormat='yyyy-MM-dd'
              placeholderText="Select Start Date"
              withPortal
              showYearDropdown
              yearDropdownItemNumber={10}
              customInput={
                <input
                  type='text'
                  id='newStartDate'
                  value={
                    newStartDate
                      ? moment(newStartDate).format("YYYY-MM-DD")
                      : ""
                  }
                  readOnly
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300 cursor-pointer"
                />
              }
            />
            <BiCalendar
              className='absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-950 cursor-pointer'
              onClick={() => setShowDatePicker(true)}
            />
          </div>
        </div>

        <button
          onClick={() => handleStartDateChange(newStartDate)}
          className={`w-full py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
            (!newStartDate || isSubmitting) ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={!newStartDate || isSubmitting}
        >
          {isSubmitting ? "Changing..." : "Change Start Date"}
        </button>
      </div>
    </div>
  );
};

export default ChangeStartDate;
