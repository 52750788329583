import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { PacmanLoader } from "react-spinners";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import Axios from "../../../service/Axios";

function DeactivationMembershipHO() {
  const [phoneUserDeactivate, setPhoneUserDeactivate] = useState("+62");
  const [showAlert, setShowAlert] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setPhoneUserDeactivate(value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    Axios
      .post(`/v1/deactivate-ho/${phoneUserDeactivate}`)
      .then((response) => {
        if (response.status === 200) {
          showAlertMessage("success", "Deactivation successful!");
        } else {
          showAlertMessage("error", "Deactivation failed. Please try again.");
        }
      })
      .catch(() => {
        showAlertMessage(
          "error",
          "An error occurred during deactivation. Please try again."
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const showAlertMessage = (type, message) => {
    setShowAlert({ type, message });
  };

  return (
    <div className='container mx-auto p-4 min-h-screen'>
      {showAlert && (
        <NotificationAlert type={showAlert.type} message={showAlert.message} />
      )}
      <div className='bg-white rounded-2xl shadow-2xl p-8 mx-auto max-w-xl transform transition-all duration-300 hover:scale-[1.01] mt-10'>
        <form onSubmit={handleSearch} className='space-y-6'>
          <div>
            <label className='block text-sm font-medium text-gray-600 mb-2'>
              Phone:
            </label>
            <input
              type='text'
              value={phoneUserDeactivate}
              onChange={handleInputChange}
              className='w-full py-3 px-4 rounded-lg border-2 border-gray-300 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800 shadow-md transition duration-300 ease-in-out'
              placeholder='Enter Phone Number'
              readOnly={isSubmitting}
            />
          </div>
          <div>
            <button
              type='submit'
              className={`w-full py-3 px-4 rounded-lg text-white font-semibold transition duration-300 ease-in-out ${
                isSubmitting
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              }`}
              disabled={isSubmitting}>
              {isSubmitting ? (
                <div className='flex items-center justify-center'>
                  <PacmanLoader color='#ffffff' size={15} />
                  <span className='ml-2'>Deactivating...</span>
                </div>
              ) : (
                "Deactivate"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DeactivationMembershipHO;
