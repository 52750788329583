import React, { useState, useEffect } from "react";
import Axios from "../../../service/Axios";
import Select from "react-select";
import NotificationAlert from "../../../components/Alert/NotificationAlert";

function ChangeHomeClub() {
  const [selectedHomeClub, setSelectedHomeClub] = useState(null);
  const [searchedUser, setSearchedUser] = useState("+62");
  const [isLocked, setIsLocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });
  const [phone, setPhone] = useState("+62");
  const [userFound, setUserFound] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShowAlert({ type: "", message: "", data: "" });

    try {
      const url = `/v1/users/query/${searchedUser}`;
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { phone, promoLocation, name } = response.data;
        setPhone(phone);
        setShowAlert({
          type: "success",
          message: "User Found.",
          data: `Current Home Club: ${promoLocation}`,
        });
        setUserFound(true);
        setIsLocked(true);
      } else {
        setShowAlert({
          type: "error",
          message: "User not found.",
        });
        setUserFound(false);
      }
    } catch (error) {
      setUserFound(false);
      setShowAlert({
        type: "error",
        message: "User not found.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnlock = () => {
    setIsLocked(false);
  };

  useEffect(() => {
    Axios
      .get("/v1/locations")
      .then((response) => {
        if (response.status === 200) {
          const filteredLocations = response.data.data.filter(
            (location) => location !== ""
          );
          setLocationOptions(filteredLocations);
        } else {
          setShowAlert({
            type: "error",
            message: "Failed to fetch location data.",
          });
        }
      })
      .catch((error) => {
        setShowAlert({
          type: "error",
          message: "An error occurred while fetching location data.",
        });
      });
  }, []);

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {
        setShowAlert({ type: "", message: "", data: "" });
      }, 15000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedHomeClub) {
      setShowAlert({
        type: "error",
        message: "Please select a new home club",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await Axios.put(
        `/v1/changehomeclub/${phone}`,
        {
          promoLocation: selectedHomeClub.value,
        },
      );

      if (response.status === 200) {
        setShowAlert({
          type: "success",
          message: "Home Club updated successfully.",
        });
        setSelectedHomeClub(null);
        setSearchedUser("+62");
        setUserFound(false);
        setIsLocked(false);
      } else {
        setShowAlert({
          type: "error",
          message: "User not found.",
        });
      }
    } catch (error) {
      setShowAlert({
        type: "error",
        message: "An error occurred. Please try again later.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto px-8">
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg border border-gray-100 p-8 mt-0 mb-64">
        {/* Phone Member Input */}
        <div className="mb-4">
          <label 
            htmlFor="phone" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Phone Number
          </label>
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
            <input
              type="text"
              id="phone"
              className="flex-grow px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
              placeholder="+62 Phone Number"
              value={searchedUser}
              onChange={(e) => setSearchedUser(e.target.value)}
              disabled={isLocked || isSubmitting}
            />
            <button
              onClick={userFound && isLocked ? handleUnlock : handleSearch}
              className={`w-full sm:w-auto px-6 py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isSubmitting}
            >
              {isLocked ? "Unlock" : "Search"}
            </button>
          </div>
        </div>

        {/* Home Club Selection */}
        <div className="mb-4">
          <label 
            htmlFor="selectedHomeClub" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Select New Home Club
          </label>
          <Select
            id="selectedHomeClub"
            value={selectedHomeClub}
            isDisabled={!userFound || isSubmitting}
            onChange={(option) => setSelectedHomeClub(option)}
            options={locationOptions.map((location) => ({
              value: location,
              label: location,
            }))}
            styles={{
              control: (provided) => ({
                ...provided,
                border: "1px solid #d1d5db",
                borderRadius: "0.5rem",
                padding: "0.25rem",
                transition: "all 0.3s ease",
                "&:hover": {
                  borderColor: "#3b82f6",
                },
                "&:focus": {
                  boxShadow: "0 0 0 2px rgba(59, 130, 246, 0.5)",
                  borderColor: "#3b82f6",
                },
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? "#3b82f6" : "white",
                color: state.isSelected ? "white" : "black",
                "&:hover": {
                  backgroundColor: state.isSelected ? "#3b82f6" : "#f3f4f6",
                },
              }),
            }}
            isSearchable={true}
            isClearable={true}
            placeholder="Select Home Club"
          />
        </div>

        <button
          onClick={handleSubmit}
          className={`w-full py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
            (!selectedHomeClub || isSubmitting) ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={!selectedHomeClub || isSubmitting}
        >
          {isSubmitting ? "Changing..." : "Change Home Club"}
        </button>
      </div>
    </div>
  );
}

export default ChangeHomeClub;
