import React, { useRef, useEffect } from "react";
import { FaTimes } from "react-icons/fa";

const LogDetailsModal = ({ log, closeModal }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscape);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeModal]);

  const formatValue = (value) => {
    if (value === null || value === undefined) return 'N/A';
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    return value.toString();
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-30 flex items-center justify-center p-4">
      <div 
        ref={modalRef}
        className="bg-white w-full max-w-3xl rounded-lg shadow-xl max-h-[90vh] overflow-hidden"
      >
        {/* Modal Header */}
        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-800">Log Details</h2>
          <button 
            onClick={closeModal}
            className="text-gray-500 hover:text-red-500 transition-colors p-2 rounded-full"
          >
            <FaTimes className="h-5 w-5" />
          </button>
        </div>

        {/* Modal Content */}
        <div className="overflow-y-auto max-h-[70vh]">
          <table className="w-full">
            <tbody>
              {Object.entries(log).map(([key, value], index) => (
                <tr 
                  key={key} 
                  className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-blue-50`}
                >
                  <td className="px-6 py-3 w-1/3 font-medium text-gray-700 text-sm border-b border-gray-200">
                    {key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  </td>
                  <td className="px-6 py-3 w-2/3 text-gray-800 text-sm border-b border-gray-200">
                    {formatValue(value)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LogDetailsModal;
