import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "moment";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import moment from "moment";
import { BiCalendar } from "react-icons/bi";
import Axios from "../../../service/Axios";

const BackdateTransactions = () => {
  const [searchedTransId, setSearchedTransId] = useState("");
  const [newDate, setNewDate] = useState(null);
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBackdate = async (e) => {
    e.preventDefault();
    if (!newDate) {
      setShowAlert({ type: "error", message: "Please select a new date." });
      return;
    }

    setIsSubmitting(true);

    try {
      const formattedDate = moment(newDate).format("YYYY-MM-DD");

      const response = await Axios.put(
        "/v1/backdate-transaction",
        {
          transId: searchedTransId,
          newDate: formattedDate,
        }
      );

      if (response.status === 200) {
        setShowAlert({
          type: "success",
          message: "Transaction backdated successfully.",
        });
        setSearchedTransId("");
        setNewDate(null);
      } else {
        setShowAlert({
          type: "error",
          message: "Backdating failed. Please try again later.",
        });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error 
      || (error.request ? "No response received from server." 
      : error.message || "An error occurred.");
  
    setShowAlert({
      type: "error",
      message: errorMessage,
    });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {
        setShowAlert({ type: "", message: "" });
      }, 15000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  return (
    <div className="container mx-auto px-8 mt-4">
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg border border-gray-100 p-8 mt-0 mb-36">
        <form
          autoComplete='off'
          onSubmit={handleBackdate}
          className='mt-2 mb-8'>
          <label
            htmlFor='searchedTransId'
            className='mb-2 block text-sm font-medium text-gray-950'>
            Transaction ID
          </label>
          <input
            autoComplete='off'
            type='text'
            id='searchedTransId'
            value={searchedTransId}
            onChange={(e) => setSearchedTransId(e.target.value)}
            className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
            placeholder='Enter Transaction ID'
            disabled={isSubmitting}
          />

          <label
            htmlFor='newDate'
            className='mb-2 mt-4 block text-sm font-medium text-gray-950'>
            New Date
          </label>
          <DatePicker
            selected={newDate}
            onChange={(date) => setNewDate(date)}
            className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
            dateFormat='yyyy-MM-dd'
            withPortal
            showYearDropdown
            yearDropdownItemNumber={10}
            customInput={
              <button
                type='button'
                className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'>
                {newDate ? moment(newDate).format("YYYY-MM-DD") : "Select Date"}
              </button>
            }
          />

          <button
            type='submit'
            className='w-full py-3 bg-gray-600 text-white rounded-lg hover:bg-yellow-600 focus:outline-none focus:ring focus:ring-blue-300 mt-8'
            disabled={!newDate || isSubmitting}>
            {isSubmitting ? "Backdating..." : "Backdate"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default BackdateTransactions;
