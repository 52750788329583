import React, { useState } from "react";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import Select from "react-select";
import Axios from "../../../service/Axios";

const initialState = {
  type: null,
  transactionID: "",
  promoCode: "",
  isSubmitting: false,
  showAlert: { type: "", message: "" },
};

function ChangePromoCode() {
  const [state, setState] = useState(initialState);

  const handleInputChange = (fieldName, value) => {
    setState((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, isSubmitting: true }));

    const endpoint = "/v1/change-promocode";

    try {
      const apiResponse = await Axios.put(
        endpoint,
        {
          typeChange: state.type?.value,
          transactionId: state.transactionID,
          promoCode: state.promoCode,
        }
      );

      if (apiResponse.status === 200) {
        setState(initialState);
        setState((prevState) => ({
          ...prevState,
          showAlert: {
            type: "success",
            message: "Data updated successfully.",
          },
        }));
      } else {
         throw new Error(apiResponse?.data?.error || "Failed to update data.");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error 
      || (error.request ? "No response received from server." 
      : error.message || "An error occurred.");
      
      setState((prevState) => ({
        ...prevState,
        showAlert: {
          type: "error",
          message: errorMessage,
        },
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  };

  const renderFormFields = () => {
    switch (state.type?.value) {
      case "Add or Replace Promo Code":
        return (
          <>
            <div className='flex justify-center flex-col mb-4'>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div className='flex flex-col'>
                  <label
                    htmlFor='transactionID'
                    className='mb-2 block text-sm font-medium text-gray-950'>
                    Transaction ID <span className="text-red-500">*</span>
                  </label>
                  <input
                    type='text'
                    id='transactionID'
                    value={state.transactionID}
                    onChange={(e) =>
                      handleInputChange("transactionID", e.target.value)
                    }
                    className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                    placeholder='Enter Transaction ID'
                  />
                </div>

                <div className='flex flex-col'>
                  <label
                    htmlFor='promoCode'
                    className='mb-2 block text-sm font-medium text-gray-950'>
                    Promo Code <span className="text-red-500">*</span>
                  </label>
                  <input
                    type='text'
                    id='promoCode'
                    value={state.promoCode}
                    onChange={(e) =>
                      handleInputChange("promoCode", e.target.value)
                    }
                    className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                    placeholder='Enter Promo Code'
                  />
                </div>
              </div>
            </div>
          </>
        );
      case "Remove Promo Code":
        return (
          <div className='mt-8 mb-8 mx-4 ml-0'>
            <label
              htmlFor='transactionID'
              className='mb-2 block text-sm font-medium text-gray-950'>
              Transaction ID: <span className="text-red-500">*</span>
            </label>
            <input
              type='text'
              id='transactionID'
              value={state.transactionID}
              onChange={(e) =>
                handleInputChange("transactionID", e.target.value)
              }
              className='py-2 px-6 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
              placeholder='Enter Transaction ID'
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-8 mt-4">
      {state.showAlert.message && (
        <NotificationAlert
          type={state.showAlert.type}
          message={state.showAlert.message}
        />
      )}
      <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg border border-gray-100 p-8 mt-0 mb-36">
        <div className="mb-4">
          <label 
            htmlFor="type" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Type <span className="text-red-500">*</span>
          </label>
          <div className="mb-6">
            <Select
              id='type'
              value={state.type}
              onChange={(selectedOption) =>
                handleInputChange("type", selectedOption)
              }
              options={[
                { value: "Remove Promo Code", label: "Remove Promo Code" },
                {
                  value: "Add or Replace Promo Code",
                  label: "Add or Replace Promo Code",
                },
              ]}
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: "8px",
                  borderRadius: "0.5rem",
                  borderColor: "#D1D5DB", // Tailwind gray-300
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#3B82F6", // Tailwind blue-500
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#3B82F6" : "white",
                  color: state.isSelected ? "white" : "black",
                  "&:hover": {
                    backgroundColor: state.isSelected ? "#3B82F6" : "#F3F4F6", // Tailwind gray-100
                  },
                }),
              }}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        </div>

        {renderFormFields()}

        <div className="mt-6">
          <button
            onClick={handleSubmit}
            className={`w-full px-6 py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
              (!state.type || !state.transactionID || state.isSubmitting) 
                ? "opacity-50 cursor-not-allowed" 
                : ""
            }`}
            disabled={!state.type || !state.transactionID || state.isSubmitting}
          >
            {state.isSubmitting ? "Updating..." : "Update Promo Code"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePromoCode;
