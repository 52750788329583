import axios from 'axios';

const Axios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

Axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
);

Axios.interceptors.response.use(
   response => {
       return response;
   }, 
   function(error) {
       if (error.response) {
           if (error.response.status === 401) {
               localStorage.clear();
               window.location.href = "/login";
           }
           return Promise.reject(error);
       }
       return Promise.reject(error);
   }
);

export default Axios;


