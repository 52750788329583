import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Axios from "../../../service/Axios";
import Select from "react-select";
import moment from "moment";
import NotificationAlert from "../../../components/Alert/NotificationAlert";

function ChangeClassCost() {
  const initialState = {
    selectedLocation: null,
    selectedInstructor: null,
    locationOptions: [],
    instructorOptions: [],
    showAlert: { type: "", message: "" },
    dateSchedule: null,
    timeSchedule: null,
    classCost: "",
    isSubmitting: false,
    fetchInstructorListInProgress: false,
    fetchInstructorListError: false,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await Axios.get("/v1/locations-dydb");
        if (response.status === 200) {
          const filteredLocations = response.data.data.filter(
            (location) =>
              location !== null &&
              location !== undefined &&
              location !== "" &&
              location.Domicile !== undefined
          );
          setState((prevState) => ({
            ...prevState,
            locationOptions: filteredLocations.map((location) => ({
              value: location.Name,
              label: location.Name,
              domicile: location.Domicile ? location.Domicile : "",
            })),
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            showAlert: { type: "error", message: "Failed to fetch location data." },
          }));
        }
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          showAlert: { type: "error", message: "An error occurred while fetching location data." },
        }));
      }
    };

    fetchLocations();
  }, []);

  const fetchInstructorList = async (domicile) => {
    setState((prevState) => ({
      ...prevState,
      fetchInstructorListInProgress: true,
      fetchInstructorListError: false,
    }));

    try {
      const response = await Axios.get(
        `/v1/instructor-list/?domicile=${encodeURIComponent(domicile)}`
      );
      if (response.status === 200) {
        setState((prevState) => ({
          ...prevState,
          instructorOptions: response.data.data.map((instructor) => ({
            value: instructor.instructor,
            label: instructor.instructor,
          })),
          fetchInstructorListInProgress: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          showAlert: { type: "error", message: "Failed to fetch instructor data." },
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        showAlert: { type: "error", message: "An error occurred while fetching instructor data." },
        fetchInstructorListInProgress: false,
        fetchInstructorListError: true,
      }));
    }
  };

  const handleChangeLocation = async (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      selectedLocation: selectedOption,
      selectedInstructor: null,
      instructorOptions: [],
    }));

    if (selectedOption) {
      const location = state.locationOptions.find(
        (loc) => loc.value === selectedOption.value
      );
      const domicile = location ? location.domicile : "";
      await fetchInstructorList(domicile);
    }
  };

  const handleChangeInstructor = (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      selectedInstructor: selectedOption,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      selectedLocation,
      selectedInstructor,
      dateSchedule,
      timeSchedule,
      classCost,
    } = state;

    setState((prevState) => ({ ...prevState, isSubmitting: true }));
    try {
      const response = await Axios.put(`/v1/change-class-cost`, {
        location_schedule: selectedLocation.value,
        instructor: selectedInstructor.value,
        date_schedule: moment(dateSchedule).format("YYYYMMDD"),
        time_schedule: timeSchedule,
        class_cost: classCost,
      });

      if (response.status === 200) {
        setState(initialState);
        setState((prevState) => ({
          ...prevState,
          showAlert: { type: "success", message: "Class Cost updated successfully." },
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          showAlert: { type: "error", message: "Failed to update data." },
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        showAlert: { type: "error", message: "An error occurred. Please ensure that the input is correct and that the rules are followed." },
      }));
    } finally {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  };

  return (
    <div className="container mx-auto px-8 mt-4">
      {state.showAlert.message && (
        <NotificationAlert
          type={state.showAlert.type}
          message={state.showAlert.message}
        />
      )}
      <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg border border-gray-100 p-8 mt-0 mb-36">
        <div className="mb-4">
          <label 
            htmlFor="selectedLocation" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Select Location
          </label>
          <div className="mb-6">
            <Select
              id='selectedLocation'
              value={state.selectedLocation}
              onChange={handleChangeLocation}
              options={state.locationOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: "8px",
                  borderRadius: "0.5rem",
                  borderColor: "#ccc", 
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#aaa", 
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#aaa" : "white",
                  color: state.isSelected ? "white" : "black",
                  "&:hover": {
                    backgroundColor: state.isSelected ? "#aaa" : "#f0f0f0", 
                  },
                }),
              }}
              isSearchable={true}
              isClearable={true}
            />
            {state.fetchInstructorListError && (
              <div className='text-red-600 mt-2'>
                Failed to fetch instructor data.
              </div>
            )}
          </div>
        </div>

        <div className="mb-4">
          <label 
            htmlFor="selectedInstructor" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Select Instructor
          </label>
          <div className="mb-6">
            <Select
              id='selectedInstructor'
              value={state.selectedInstructor}
              isDisabled={
                state.fetchInstructorListInProgress ||
                state.fetchInstructorListError
              }
              onChange={handleChangeInstructor}
              options={state.instructorOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: "8px",
                  borderRadius: "0.5rem",
                  borderColor: "#ccc", 
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#aaa", 
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#aaa" : "white",
                  color: state.isSelected ? "white" : "black",
                  "&:hover": {
                    backgroundColor: state.isSelected ? "#aaa" : "#f0f0f0", 
                  },
                }),
              }}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div className="flex flex-col">
            <label
              htmlFor='dateSchedule'
              className='mb-2 block text-sm font-medium text-gray-950'>
              Date Schedule
            </label>
            <DatePicker
              selected={state.dateSchedule}
              onChange={(date) =>
                setState((prevState) => ({ ...prevState, dateSchedule: date }))
              }
              className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
              dateFormat='yyyy-MM-dd'
              withPortal
              showYearDropdown
              yearDropdownItemNumber={10}
              customInput={
                <button
                  type='button'
                  className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'>
                  {state.dateSchedule
                    ? moment(state.dateSchedule).format("YYYY-MM-DD")
                    : "Select Date"}
                </button>
              }
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor='timeSchedule'
              className='mb-2 block text-sm font-medium text-gray-950'>
              Time Schedule
            </label>
            <input
              type='time'
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  timeSchedule: e.target.value,
                }))
              }
              value={state.timeSchedule}
              className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
            />
          </div>
        </div>

        <div className="flex flex-col mb-6">
          <label
            htmlFor='classCost'
            className='mb-2 block text-sm font-medium text-gray-950'>
            New Class Cost
          </label>
          <input
            type='text'
            onChange={(e) =>
              setState((prevState) => ({
                ...prevState,
                classCost: e.target.value,
              }))
            }
            value={state.classCost}
            className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
            placeholder='Enter Class Cost'
          />
        </div>

        <button
          type='submit'
          onClick={handleSubmit}
          className='w-full py-3 bg-gray-600 text-white rounded-lg hover:bg-yellow-600 focus:outline-none focus:ring focus:ring-blue-300'
          disabled={
            !state.selectedLocation ||
            !state.dateSchedule ||
            !state.classCost
          }>
          {state.isSubmitting ? "Changing..." : "Change"}
        </button>
      </div>
    </div>
  );
}

export default ChangeClassCost;
