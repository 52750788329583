import React, { useState } from "react";
import Axios from "../../../service/Axios";
import Select from "react-select";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import Datepicker from "react-tailwindcss-datepicker";

// Initial state for the component's state management
const initialState = {
  selectedPackage: null,
  searchedUser: "+62",
  uid: "",
  showAlert: null,
  packageOptions: [],
  selectStartDatePT: { startDate: null },
  userPTPackages: [],
  isSubmitting: false,
};

function ChangeStartDatePT() {
  const [state, setState] = useState(initialState);

  // Handle date selection for the new start date
  const selectDate = (selectStartDatePT) => {
    setState({ ...state, selectStartDatePT });
  };

  // Custom styles for the date picker
  const datePickerStyles = {
    border: "solid",
    borderColor: "red",
    borderRadius: "5px",
  };

  // Search for user by phone number and retrieve their PT packages
  const handleSearch = async (e) => {
    e.preventDefault();
    setState({ ...state, showAlert: null });

    try {
      const url = `/v1/users/query/${state.searchedUser}`;
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { uid, name, UserPTPackages } = response.data;

        setState({
          ...state,
          uid,
          showAlert: { type: "success", message: `User Found. ${name}` },
          // Transform user PT packages into select options
          packageOptions: UserPTPackages.map((userPackage) => ({
            value: userPackage.id,
            label: `${userPackage.package_name} (${userPackage.session_left}/${userPackage.session_total} sessions)`,
            startDate: userPackage.start_date,
            expiredDate: userPackage.expired_date,
            isActive: userPackage.is_active,
            transaction_pt_id: userPackage.transaction_pt_id
          })),
          userPTPackages: UserPTPackages,
        });
      } else {
        setState({
          ...initialState,
          showAlert: { type: "error", message: "User not found." },
        });
      }
    } catch (error) {
      setState({
        ...initialState,
        showAlert: {
          type: "error",
          message: "User does not have PT packages.",
        },
      });
    }
  };

  // Custom styles for the Select component
  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "0.25rem",
      borderRadius: "0.5rem",
      borderColor: "#D1D5DB",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#3B82F6"
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3B82F6" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: state.isSelected ? "#3B82F6" : "#F3F4F6"
      }
    })
  };

  // Handle package selection
  const handleChange = (selectedOption) => {
    setState({ ...state, selectedPackage: selectedOption });
  };

  // Submit new start date for the selected PT package
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate user has PT packages
    if (!state.userPTPackages || state.userPTPackages.length === 0) {
      setState({
        ...state,
        showAlert: {
          type: "error",
          message: "User does not have PT packages.",
        },
      });
      return;
    }

    // Validate selected package is active
    if (!state.selectedPackage || !state.selectedPackage.isActive) {
      setState({
        ...state,
        showAlert: {
          type: "error",
          message: "Please select an active PT package.",
        },
      });
      return;
    }

    setState({ ...state, isSubmitting: true });

    try {
      // Send request to update start date
      const response = await Axios.put(
        `/v1/update-startdate-pt/${state.uid}`,
        {
          newStartDate: state.selectStartDatePT.startDate,
          packageId: state.selectedPackage.transaction_pt_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setState({
          ...state,
          showAlert: {
            type: "success",
            message: "Start Date updated successfully.",
          },
          selectedPackage: null,
          searchedUser: "+62",
        });
      } else {
        setState({
          ...state,
          showAlert: {
            type: "error",
            message: "Failed to update Start Date.",
          },
        });
      }
    } catch (error) {
      setState({
        ...state,
        showAlert: {
          type: "error",
          message: "An error occurred. Please ensure that the input is correct and that the rules are followed.",
        },
      });
    }
  };

  return (
    <div className="container mx-auto px-8">
      {/* Notification alert for user feedback */}
      {state.showAlert && (
        <NotificationAlert
          type={state.showAlert.type}
          message={state.showAlert.message}
        />
      )}
      <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg border border-gray-100 p-8 mt-0 mb-56">
        {/* Phone Number Search Section */}
        <div className="mb-4">
          <label 
            htmlFor="phone" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Phone Number
          </label>
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
            <input
              type="text"
              id="phone"
              className="flex-grow px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
              placeholder="+62 Phone Number"
              value={state.searchedUser}
              onChange={(e) =>
                setState({ ...state, searchedUser: e.target.value })
              }
              disabled={state.isSubmitting}
            />
            <button
              onClick={handleSearch}
              className={`w-full sm:w-auto px-6 py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
                !state.searchedUser ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={!state.searchedUser || state.isSubmitting}
            >
              Search
            </button>
          </div>
        </div>

        {/* PT Packages Selection Section */}
        <div className="mb-4">
          <label 
            htmlFor="selectedPackage" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Select PT Packages
          </label>
          <Select
            key={state.userPTPackages.length}
            id="selectedPackage"
            value={state.selectedPackage}
            isDisabled={state.searchedUser === "+62"}
            onChange={handleChange}
            options={state.packageOptions}
            styles={customStyles}
            isSearchable={true}
            isClearable={true}
            placeholder="Select Package"
          />
        </div>

        {/* New Start Date Selection Section */}
        <div className="mb-4">
          <label 
            htmlFor="startDate" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            New Start Date
          </label>
          <Datepicker
            inputClassName="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
            toggleClassName="absolute bg-gray-500 rounded-r-lg text-white right-0 h-full px-3 text-gray-950 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
            value={state.selectStartDatePT}
            asSingle={true}
            onChange={selectDate}
            showShortcuts={true}
            displayFormat={"DD/MM/YYYY"}
          />
        </div>

        {/* Submit Button Section */}
        <button
          onClick={handleSubmit}
          className={`w-full py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
            (!state.selectedPackage || !state.selectStartDatePT.startDate || state.isSubmitting) 
              ? "opacity-50 cursor-not-allowed" 
              : ""
          }`}
          disabled={
            !state.selectedPackage || !state.selectStartDatePT.startDate || state.isSubmitting
          }
        >
          {state.isSubmitting ? "Changing..." : "Change Start Date"}
        </button>
      </div>
    </div>
  );
}

export default ChangeStartDatePT;
