import React from 'react';

function NotificationAlert({ type, message, data }) {
  const alertClasses = `
    max-w-2xl mx-auto my-4 
    border-l-4 p-4 
    ${
      type === 'error' 
        ? 'bg-red-50 border-red-500 text-red-700' 
        : 'bg-green-50 border-green-500 text-green-700'
    }
    rounded-r-md
    shadow-sm
  `;

  const messageClasses = `
    font-medium text-lg
  `;

  const dataClasses = `
    text-sm text-gray-950 mt-1
  `;

  return (
    <div className={alertClasses} role="alert">
      <div>
        <p className={messageClasses}>{message}</p>
        {data && (
          <p className={dataClasses}>{data}</p>
        )}
      </div>
    </div>
  );
}

export default NotificationAlert;
