import React, { useState, useEffect } from "react";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import Axios from "../../../service/Axios";

function ChangeName() {
  const [newNameMember, setNewNameMember] = useState("");
  const [searchedUser, setSearchedUser] = useState("+62");
  const [isLocked, setIsLocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });
  const [phone, setPhone] = useState("+62");
  const [userFound, setUserFound] = useState(false);

  const changeUserName = async (e) => {
    e.preventDefault();
    if (!newNameMember) {
      setShowAlert({
        type: "error",
        message: "Please input a new name for the member",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await Axios.put(
        "/v1/changename",
        {
          phoneNumber: phone,
          newUserName: newNameMember,
        },
      );

      if (response.status === 200) {
        setShowAlert({
          type: "success",
          message: "Name changed successfully.",
        });
        setNewNameMember("");
        setSearchedUser("+62");
      } else {
        setShowAlert({
          type: "error",
          message: "User not found.",
        });
        setUserFound(false);
      }
    } catch (error) {
      setShowAlert({
        type: "error",
        message: "An error occurred. Please try again later.",
      });
      setUserFound(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShowAlert({ type: "", message: "", data: "" });

    try {
      const url = `/v1/users/query/${searchedUser}`;
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { phone, name } = response.data;
        setPhone(phone);
        setShowAlert({
          type: "success",
          message: "User Found.",
          data: name,
        });
        setUserFound(true);
      } else {
        setShowAlert({
          type: "error",
          message: "User not found.",
        });
        setUserFound(false);
      }
    } catch (error) {
      setUserFound(false);
      setShowAlert({
        type: "error",
        message: "User not found.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnlock = () => {
    setIsLocked(false);
  };

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {
        setShowAlert({ type: "", message: "", data: "" });
      }, 15000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  return (
    <div className="container mx-auto px-8">
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg border border-gray-100 p-8 mt-0 mb-56">
        {/* Phone Member Input */}
        <div className="mb-4">
          <label 
            htmlFor="phone" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Phone Number
          </label>
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
            <input
              type="text"
              id="phone"
              className="flex-grow px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
              placeholder="+62 Phone Number"
              value={searchedUser}
              onChange={(e) => setSearchedUser(e.target.value)}
              disabled={isLocked || isSubmitting}
            />
            <button
              onClick={userFound && isLocked ? handleUnlock : handleSearch}
              className={`w-full sm:w-auto px-6 py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isSubmitting}
            >
              {isLocked ? "Unlock" : "Search"}
            </button>
          </div>
        </div>

        {/* New Name Member Input */}
        <div className="mb-4">
          <label 
            htmlFor="newNameMember" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            New Name
          </label>
          <input
            type="text"
            id="newNameMember"
            value={newNameMember}
            onChange={(e) => setNewNameMember(e.target.value)}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
            placeholder="Enter new name"
          />
        </div>

        <button
          onClick={changeUserName}
          className={`w-full py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
            (!newNameMember || isSubmitting) ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={!newNameMember || isSubmitting}
        >
          {isSubmitting ? "Changing..." : "Change Name"}
        </button>
      </div>
    </div>
  );
}

export default ChangeName;
