import React, { useState, useEffect } from "react";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import Select from "react-select";
import Axios from "../../../service/Axios";

function ChangePaymentMethods() {
  const [type, setType] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [transactionID, setTransactionID] = useState("");
  const [paymentMethod1, setPaymentMethod1] = useState(null);
  const [paymentAmount1, setPaymentAmount1] = useState("");
  const [paymentID1, setPaymentID1] = useState("");
  const [paymentMethod2, setPaymentMethod2] = useState(null);
  const [paymentAmount2, setPaymentAmount2] = useState("");
  const [paymentID2, setPaymentID2] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "8px",
      borderRadius: "0.5rem",
      borderColor: "#D1D5DB", // Tailwind gray-300
      boxShadow: "none",
      "&:hover": {
        borderColor: "#3B82F6", // Tailwind blue-500
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3B82F6" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: state.isSelected ? "#3B82F6" : "#F3F4F6", // Tailwind gray-100
      },
    }),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await Axios.put("/v1/change-payment-method", {
        typeChange: type?.value,
        transactionId: transactionID,
        pmID1: paymentMethod1?.value,
        paymentMethod1: paymentMethod1?.label || "",
        paymentAmount1,
        paymentID1,
        pmID2: paymentMethod2?.value,
        paymentMethod2: paymentMethod2?.label || "",
        paymentAmount2,
        paymentID2,
      });

      if (response.status === 200) {
        setShowAlert({
          type: "success",
          message: "Payment Methods updated successfully.",
        });
        setType(null);
        setTransactionID("");
        setPaymentMethod1(null);
        setPaymentAmount1("");
        setPaymentID1("");
        setPaymentMethod2(null);
        setPaymentAmount2("");
        setPaymentID2("");
      } else {
        setShowAlert({
          type: "error",
          message: "Failed to update Payment Methods.",
        });
      }
    } catch (error) {
      setShowAlert({
        type: "error",
        message:
          "An error occurred. Please ensure that the input is correct and that the rules are followed.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {}, 20000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await Axios.get("/v1/payment-method-list");
        setPaymentMethods(response.data);
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      }
    };

    fetchPaymentMethods();
  }, []);

  const renderPaymentOptions = () => {
    return paymentMethods
      .filter((paymentMethod) => paymentMethod)
      .map((paymentMethod) => ({
        value: paymentMethod.PaymentId,
        label: paymentMethod.PaymentName,
      }));
  };

  const renderFormFields = () => {
    switch (type?.value) {
      case "change_payment_method":
        return (
          <div className='flex justify-center flex-col mb-4'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='flex flex-col'>
                <label
                  htmlFor='transactionID'
                  className='mb-2 block text-sm font-medium text-gray-950'>
                  Transaction ID
                </label>
                <input
                  type='text'
                  id='transactionID'
                  value={transactionID}
                  onChange={(e) => setTransactionID(e.target.value)}
                  className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                  placeholder='Enter Transaction ID'
                />
              </div>

              <div className='flex flex-col'>
                <label
                  htmlFor='paymentMethod1'
                  className='mb-2 block text-sm font-medium text-gray-950'>
                  Payment Method 1
                </label>
                <Select
                  id='paymentMethod1'
                  value={paymentMethod1}
                  onChange={(selectedOption) => setPaymentMethod1(selectedOption)}
                  options={renderPaymentOptions()}
                  styles={customStyles}
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
              <div className='flex flex-col'>
                <label
                  htmlFor='paymentID1'
                  className='mb-2 block text-sm font-medium text-gray-950'>
                  Payment ID 1
                </label>
                <input
                  type='text'
                  id='paymentID1'
                  value={paymentID1}
                  onChange={(e) => setPaymentID1(e.target.value)}
                  className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                  placeholder='Enter Payment ID 1'
                />
              </div>
            </div>
          </div>
        );
      case "change_payment_method_split":
        return (
          <div className='flex justify-center flex-col mb-4'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='flex flex-col'>
                <label
                  htmlFor='transactionID'
                  className='mb-2 block text-sm font-medium text-gray-950'>
                  Transaction ID
                </label>
                <input
                  type='text'
                  id='transactionID'
                  value={transactionID}
                  onChange={(e) => setTransactionID(e.target.value)}
                  className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                  placeholder='Enter Transaction ID'
                />
              </div>

              <div className='flex flex-col'>
                <label
                  htmlFor='paymentMethod1'
                  className='mb-2 block text-sm font-medium text-gray-950'>
                  Payment Method 1
                </label>
                <Select
                  id='paymentMethod1'
                  value={paymentMethod1}
                  onChange={(selectedOption) => setPaymentMethod1(selectedOption)}
                  options={renderPaymentOptions()}
                  styles={customStyles}
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
              <div className='flex flex-col'>
                <label
                  htmlFor='paymentAmount1'
                  className='mb-2 block text-sm font-medium text-gray-950'>
                  Payment Amount 1
                </label>
                <input
                  type='text'
                  id='paymentAmount1'
                  value={paymentAmount1}
                  onChange={(e) => setPaymentAmount1(e.target.value)}
                  className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                  placeholder='Enter Payment Amount 1'
                />
              </div>
              <div className='flex flex-col'>
                <label
                  htmlFor='paymentID1'
                  className='mb-2 block text-sm font-medium text-gray-950'>
                  Payment ID 1
                </label>
                <input
                  type='text'
                  id='paymentID1'
                  value={paymentID1}
                  onChange={(e) => setPaymentID1(e.target.value)}
                  className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                  placeholder='Enter Payment ID 1'
                />
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
              <div className='flex flex-col'>
                <label
                  htmlFor='paymentMethod2'
                  className='mb-2 block text-sm font-medium text-gray-950'>
                  Payment Method 2
                </label>
                <Select
                  id='paymentMethod2'
                  value={paymentMethod2}
                  onChange={(selectedOption) => setPaymentMethod2(selectedOption)}
                  options={renderPaymentOptions()}
                  styles={customStyles}
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
              <div className='flex flex-col'>
                <label
                  htmlFor='paymentAmount2'
                  className='mb-2 block text-sm font-medium text-gray-950'>
                  Payment Amount 2
                </label>
                <input
                  type='text'
                  id='paymentAmount2'
                  value={paymentAmount2}
                  onChange={(e) => setPaymentAmount2(e.target.value)}
                  className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                  placeholder='Enter Payment Amount 2'
                />
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
              <div className='flex flex-col'>
                <label
                  htmlFor='paymentID2'
                  className='mb-2 block text-sm font-medium text-gray-950'>
                  Payment ID 2
                </label>
                <input
                  type='text'
                  id='paymentID2'
                  value={paymentID2}
                  onChange={(e) => setPaymentID2(e.target.value)}
                  className='w-full py-2 px-4 border rounded-lg focus:outline-none focus:ring focus:border-blue-500'
                  placeholder='Enter Payment ID 2'
                />
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-8 mt-24">
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg border border-gray-100 p-8 mt-0 mb-36">
        <div className="mb-4">
          <label 
            htmlFor="type" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Type
          </label>
          <div className="mb-6">
            <Select
              id='type'
              value={type}
              onChange={(selectedOption) => setType(selectedOption)}
              options={[
                {
                  value: "change_payment_method",
                  label: "Change Payment Method",
                },
                {
                  value: "change_payment_method_split",
                  label: "Change Payment Method Split",
                },
              ]}
              styles={customStyles}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        </div>

        {renderFormFields()}

        <div className="mt-6">
          <button
            onClick={handleSubmit}
            className={`w-full ${
              transactionID.trim() 
                ? 'bg-blue-500 hover:bg-blue-600' 
                : 'bg-gray-400 cursor-not-allowed'
            } text-white py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-500`}
            disabled={!transactionID.trim() || isSubmitting}
          >
            {isSubmitting ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePaymentMethods;
