import React, { useState } from "react";
import { useTheme } from "../../context/ThemeContext";
import { Link, useLocation } from "react-router-dom";
import {
  FaChevronRight,
  FaTicketAlt,
  FaChartBar,
  FaRedoAlt,
  FaBolt,
} from "react-icons/fa";

function MenuItem({ to, text, showIcon }) {
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <li
      className={`
        relative 
        p-1 
        transition-all 
        duration-300 
        ease-in-out 
        mb-2 
        pr-8 
        transform 
        hover:scale-105 
        hover:translate-x-2 
        hover:shadow-lg
        ${isActive ? 'bg-blue-500/20 shadow-md' : ''}
      `}
      style={{ 
        borderBottom: "1.5px solid #3498db",
        transformStyle: "preserve-3d",
        perspective: "500px",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link
        to={to}
        className='block text-xs font-bold text-white hover:text-yellow-500 relative'
        style={{
          transform: isHovered ? "rotateX(5deg) translateZ(10px)" : "rotateX(0deg) translateZ(0px)",
          textRendering: "optimizeLegibility",
          WebkitFontSmoothing: "antialiased",
          letterSpacing: "0.6px",
        }}>
        <span
          className='font-extrabold'
          style={{ 
            display: "flex", 
            alignItems: "center",
            fontSize: "13.8px",
            lineHeight: "2.5"
          }}>
          {showIcon && (
            <span
              className={`
                w-5 
                h-5 
                mt-2 
                ${isHovered ? "text-yellow-500" : "text-gray-600"}
                transform 
                transition-all 
                duration-300
                ${isHovered ? "translate-x-1" : ""}
              `}
              style={{
                transform: isHovered ? "translateZ(20px)" : "translateZ(0)",
                transition: "all 0.3s ease-out"
              }}>
              <FaChevronRight />
            </span>
          )}
          <span className={`
            transition-all 
            duration-300 
            ${isHovered ? "translate-x-1" : ""}
            ${isActive ? "text-yellow-400" : ""}
          `}>
            {text}
          </span>
        </span>
      </Link>
    </li>
  );
}

function SubMenu({ items, isVisible, isDarkMode, showIcon }) {
  const userRole = localStorage.getItem("role");

  if (!isVisible) return null;
  const filterDataItem = items.filter((item) => item.role.includes(userRole));

  // const filterDataItem = items.filter((item) => {
  //   console.log(item.featureFlag)
  //   const featureFlag = item.featureFlag;
  //   if (!featureFlag) return true; // If there's no featureFlag, return true to display the item
  //   const shouldDisplay = featureFlag === "true"; // Check if the featureFlag is 'true'
  //   return item.role.includes(userRole) || shouldDisplay;
  // });

  return (
    <ul
      className={`space-y-2 mt-6 px-10 ${
        isDarkMode
          ? "text-gray-400 hover:bg-slate-600 hover:bg-opacity-50 rounded-lg p-2 pl-5 shadow-lg"
          : "text-white hover:bg-gray-600 hover:bg-opacity-50 rounded-lg p-2 pl-5 shadow-lg"
      }`}
      style={{
        transform: "translateX(-5px)",
      }}>
      {filterDataItem.map((item, index) => (
        <MenuItem
          key={index}
          to={item.to}
          text={item.text}
          showIcon={showIcon}
        />
      ))}
    </ul>
  );
}

function Sidebar() {
  const { isDarkMode, toggleDarkMode } = useTheme();
  // Use localStorage to persist menu state
  const [isDashboardSubMenuVisible, setDashboardSubMenuVisible] = useState(
    localStorage.getItem('dashboardMenuOpen') === 'true'
  );
  const [isTicketSubMenuVisible, setTicketSubMenuVisible] = useState(
    localStorage.getItem('ticketMenuOpen') === 'true'
  );
  const [isRecurringSubMenuVisible, setRecurringSubMenuVisible] = useState(
    localStorage.getItem('recurringMenuOpen') === 'true'
  );
  const [isSidebarMinimized, setSidebarMinimized] = useState(false);

  const toggleDashboardSubMenu = () => {
    const newState = !isDashboardSubMenuVisible;
    setDashboardSubMenuVisible(newState);
    localStorage.setItem('dashboardMenuOpen', newState);
  };

  const toggleTicketSubMenu = () => {
    const newState = !isTicketSubMenuVisible;
    setTicketSubMenuVisible(newState);
    localStorage.setItem('ticketMenuOpen', newState);
  };

  const toggleRecurringSubMenu = () => {
    const newState = !isRecurringSubMenuVisible;
    setRecurringSubMenuVisible(newState);
    localStorage.setItem('recurringMenuOpen', newState);
  };

  const toggleSidebarMinimized = () => {
    setSidebarMinimized(!isSidebarMinimized);
  };

  const sidebarClasses = isDarkMode
    ? "bg-slate-700 text-white"
    : "bg-gradient-to-b from-gray-800 via-gray-700 to-gray-900 text-white";

  const logoStyles = {
    width: "15px",
    height: "18px",
    marginRight: "15px",
    marginLeft: "8px",
  };

  const menuLineStyles = {
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.5)",
    marginBottom: "15px",
    transition: "box-shadow 0.5s ease-in-out",
  };

  return (
    <div
      className={`w-1/2 ${sidebarClasses} p-4 relative ${
        isSidebarMinimized ? "w-16" : "w-1/4"
      }`}
      style={{
        flexBasis: "max-content",
        borderTopRightRadius: "15px",
        borderBottomRightRadius: "15px",
        boxShadow:
          "0 15px 35px rgba(0,0,0,0.1), 0 5px 15px rgba(0,0,0,0.1), inset 0 0 15px rgba(255,255,255,0.05)",
        backdropFilter: "blur(15px)",
        paddingRight: "50px",
        paddingLeft: "20px",
        transition: "width 0.4s ease-in-out, transform 0.3s ease-out",
        zoom: "85%",
        perspective: "1000px",
        transform: `rotateY(${isSidebarMinimized ? "0deg" : "0deg"}) translateZ(${isSidebarMinimized ? "-50px" : "0"})`
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = `rotateY(${isSidebarMinimized ? "0eg" : "2deg"}) translateZ(${isSidebarMinimized ? "-50px" : "10px"})`
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = `rotateY(${isSidebarMinimized ? "0deg" : "0deg"}) translateZ(${isSidebarMinimized ? "-50px" : "0"})`
      }}
    >
      <div className='absolute top-1/2 transform -translate-y-1/2 right-2 mr-1'>
        <button
          onClick={toggleSidebarMinimized}
          className={`w-8 h-8 ml-16 focus:outline-none text-white relative ${
            isSidebarMinimized ? "before:block" : "before:hidden"
          }`}
          style={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3), 0px 2px 4px rgba(0, 0, 0, 0.2) inset",
            transition:
              "box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, width 0.3s ease-in-out",
            transform: isSidebarMinimized 
              ? "rotate(0deg) translateZ(-10px)" 
              : "rotate(180deg) translateZ(10px)",
            transformStyle: "preserve-3d",
            perspective: "700px",
            borderRadius: "50%",
            padding: "5px",
            backgroundColor: isSidebarMinimized
              ? "rgba(0, 0, 0, 0.2)"
              : "rgba(0, 0, 0, 0.4)",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = isSidebarMinimized 
              ? "rotate(0deg) translateZ(-5px) scale(1.1)" 
              : "rotate(180deg) translateZ(15px) scale(1.1)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = isSidebarMinimized 
              ? "rotate(0deg) translateZ(-10px) scale(1)" 
              : "rotate(180deg) translateZ(10px) scale(1)";
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              transform: isSidebarMinimized
                ? "rotate(0deg)"
                : "rotate(-180deg)",
              transition: "transform 0.3s ease-in-out",
            }}>
            <span
              className='w-5 h-5 text-gray-400 hover:text-yellow-500 mr-2'
              style={{
                borderRadius: "50%",
                backgroundColor: "transparent",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "6px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              }}>
              <FaChevronRight
                style={{
                  transform: isSidebarMinimized
                    ? "rotate(0deg)"
                    : "rotate(-180deg)",
                }}
              />
            </span>
          </div>
        </button>
      </div>

      {/* Content */}
      {!isSidebarMinimized && (
        <>
          <div className='flex items-center mb-8 mt-4 ml-0'>
            <Link to='/' className='flex items-center cursor-pointer'>
              <FaBolt
                className={`h-8 w-8 mr-2 mb-2 mt-2 ${
                  isDarkMode ? 'animate-pulse text-yellow-400' : 'text-yellow-500'
                }`}
                style={{
                  transform: 'perspective(200px) rotateY(10deg)',
                  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                  borderRadius: '50%',
                  padding: '4px',
                  transition: 'transform 0.3s ease-in-out',
                }}
              />
              <h1 
                className='font-bold text-white' 
                style={{
                  textShadow: '2px 2px 4px rgba(0,0,0,0.3), -1px -1px 2px rgba(255,255,255,0.1)',
                  transform: 'perspective(300px) rotateX(3deg)',
                  transition: 'transform 0.3s ease-in-out',
                  letterSpacing: '0.5px',
                  fontSize: '22px'
                }}
              >
                TechOps - Dashboard
              </h1>
            </Link>
          </div>

          <ul className='space-y-3'>
            {/* Dashboard */}
            <li
              className={`hover:bg-slate-600 hover:bg-opacity-50 rounded-lg p-2 transition duration-300 ease-in-out ${
                isDashboardSubMenuVisible ? "bg-slate-500 bg-opacity-50" : ""
              }`}
              style={menuLineStyles}>
              <button 
                onClick={toggleDashboardSubMenu} 
                className='w-full flex items-center text-lg pr-6 font-bold text-white hover:text-yellow-500'
              >
                <FaChartBar className='h-4 w-4 mr-2' style={logoStyles} />
                Dashboard
              </button>
              <SubMenu
                items={[
                  {
                    to: "/task-board",
                    text: "Ticketing Board",
                    role: ["mantainer"],
                    featureFlag: process.env.REACT_APP_FF_TICKETING_BOARD,
                  },
                  {
                    to: "/",
                    text: "Ticketing Logs List",
                    role: ["admin", "finance", "ops"],
                  },
                ]}
                isVisible={isDashboardSubMenuVisible}
                isDarkMode={isDarkMode}
                showIcon={!isSidebarMinimized}
              />
            </li>

            {/* Recurring Task */}
            <li
              className={`hover:bg-slate-700 hover-bg-opacity-50 rounded-lg p-2 transition duration-300 ease-in-out ${
                isRecurringSubMenuVisible ? "bg-slate-600 bg-opacity-50" : ""
              }`}
              style={menuLineStyles}>
              <button
                onClick={toggleRecurringSubMenu}
                className='w-full flex items-center text-lg pr-6 font-bold text-white hover:text-yellow-500'>
                <FaRedoAlt className='h-6 w-6 mr-2' style={logoStyles} />
                Recurring Task
              </button>
              <SubMenu
                items={[
                  {
                    to: "/activation-membership-ho",
                    text: "Activate Membership HO",
                    role: ["admin"],
                  },
                  {
                    to: "/deactivation-membership-ho",
                    text: "Deactivate Membership HO",
                    role: ["admin"],
                  },
                  {
                    to: "/extend-membership-csv",
                    text: "Extend Membership CSV",
                    role: ["admin"],
                  },
                  {
                    to: "/extend-membership-userappid-csv",
                    text: "Extend Membership ID",
                    role: ["admin"],
                  },
                ]}
                isVisible={isRecurringSubMenuVisible}
                isDarkMode={isDarkMode}
                showIcon={!isSidebarMinimized}
              />
            </li>

            {/* Ticketing */}
            <li
              className={`hover:bg-slate-700 hover-bg-opacity-50 rounded-lg p-2 transition duration-300 ease-in-out ${
                isTicketSubMenuVisible ? "bg-slate-600 bg-opacity-50" : ""
              }`}
              style={menuLineStyles}>
              <button
                onClick={toggleTicketSubMenu}
                className='w-full flex items-center text-lg pr-6 font-bold text-white hover:text-yellow-500'>
                <FaTicketAlt className='h-6 w-6 mr-2' style={logoStyles} />
                Ticketing
              </button>
              <SubMenu
                items={[
                  {
                    to: "/change-name",
                    text: "Change Name",
                    role: ["admin", "ops"],
                  },
                  {
                    to: "/change-email",
                    text: "Change Email",
                    role: ["admin", "ops"],
                  },
                  {
                    to: "/change-home-club",
                    text: "Change Home Club",
                    role: ["admin", "ops"],
                  },
                  {
                    to: "/change-start-date",
                    text: "Change Start Date",
                    role: ["admin", "ops"],
                  },
                  {
                    to: "/change-start-date-pt",
                    text: "Change Start Date PT",
                    role: ["admin", "ops"],
                  },
                  {
                    to: "/change-expired-date",
                    text: "Change Expired Date",
                    role: ["admin", "ops"],
                  },
                  {
                    to: "/change-promo-code",
                    text: "Change Promo Code",
                    role: ["admin", "finance"],
                  },
                  {
                    to: "/change-package-plan",
                    text: "Change Packages",
                    role: ["admin", "finance"],
                  },
                  {
                    to: "/change-payment-methods",
                    text: "Change Payment Method",
                    role: ["admin", "finance"],
                  },
                  {
                    to: "/change-class-cost",
                    text: "Change Class Cost",
                    role: ["admin", "ops"],
                  },
                  {
                    to: "/backdate-transaction",
                    text: "Back Date Transaction",
                    role: ["admin", "finance"],
                  },
                ]}
                isVisible={isTicketSubMenuVisible}
                isDarkMode={isDarkMode}
                showIcon={!isSidebarMinimized}
              />
            </li>
          </ul>
        </>
      )}
    </div>
  );
}

export default Sidebar;
