import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faSignOutAlt, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./Header.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext";

const Header = () => {
  const [ticketsEnteredToday, setTicketsEnteredToday] = useState(0);
  const [ticketsDoneToday, setTicketsDoneToday] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode, toggleDarkMode } = useTheme();

  // Mapping of route paths to readable titles
  const pageTitles = {
    "/change-promo-code": "Change Promo Code",
    "/change-email": "Change Email",
    "/change-name": "Change Name",
    "/change-home-club": "Change Home Club",
    "/change-package-plan": "Change Packages",
    "/change-payment-methods": "Change Payment Method",
    "/change-start-date": "Change Start Date",
    "/change-start-date-pt": "Change PT Start Date",
    "/change-expired-date": "Change Expired Date",
    "/change-class-cost": "Change Class Cost",
    "/delete-transaction": "Delete Transaction",
    "/backdate-transaction": "Backdate Transaction",
    "/activation-membership-ho": "[ Activate | Extend ] Membership HO",
    "/deactivation-membership-ho": "Deactivation Membership HO",
    "/ticketing-board": "Ticketing Board",
    "/": "Ticketing Logs",
  };

  // Ticket count interval
  useEffect(() => {
    const interval = setInterval(() => {
      const newTicketsEntered = Math.floor(Math.random() * 5);
      const newTicketsDone = Math.floor(Math.random() * 3);

      setTicketsEnteredToday((prev) => prev + newTicketsEntered);
      setTicketsDoneToday((prev) => prev + newTicketsDone);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  // Check if the header content should be shown based on environment variable
  const showHeaderContent = process.env.REACT_APP_FF_TICKETING_COUNT === "true";

  // Get the current page title, default to Dashboard if not found
  const currentPageTitle = pageTitles[location.pathname] || "Dashboard";

  // Define the container style based on whether showHeaderContent is true
  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.75rem 1.5rem",
    height: "4rem",
    background: "rgba(255, 255, 255, 0.5)",
    backdropFilter: "blur(20px) saturate(180%)", // macOS-like blur effect
    WebkitBackdropFilter: "blur(20px) saturate(180%)", // Safari compatibility
    borderRadius: "1rem",
    boxShadow: "0 10px 25px rgba(0,0,0,0.1), 0 2px 10px rgba(0,0,0,0.05)", // Subtle, modern shadow
    border: isDarkMode
      ? "1px solid rgba(255,255,255,0.1)" // Subtle border for dark mode
      : "1px solid rgba(0,0,0,0.05)", // Subtle border for light mode
    transition: "all 0.3s ease",
    position: "relative",
    overflow: "hidden",
    // Gradient overlay for additional depth
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: isDarkMode
        ? "linear-gradient(to bottom, rgba(45, 55, 72, 0.5), rgba(30, 41, 59, 0.7))"
        : "linear-gradient(to bottom, rgba(255,255,255,0.3), rgba(255,255,255,0.7))",
      zIndex: 1,
      pointerEvents: "none"
    }
  };

  return (
    <div className='header-container mx-4 my-2 flex items-center justify-between relative' style={containerStyle}>
      {/* Dark mode toggle button - Left side */}
      <div className='dark-mode-toggle flex items-center absolute left-6'>
        <button
          onClick={toggleDarkMode}
          className={`
            relative 
            w-12 
            h-6 
            rounded-full 
            focus:outline-none 
            transition-all 
            duration-300 
            ease-in-out 
            ${isDarkMode 
              ? 'bg-yellow-400' 
              : 'bg-gray-200'}
            flex 
            items-center 
            group
          `}
          title={`Switch to ${isDarkMode ? 'Light' : 'Dark'} Mode`}
        >
          <div 
            className={`
              absolute 
              w-8 
              h-8 
              -top-1 
              rounded-full 
              transform 
              transition-transform 
              duration-300 
              ease-in-out
              ${isDarkMode 
                ? 'translate-x-full bg-white' 
                : 'translate-x-0 bg-white'}
              shadow-md
              flex 
              items-center 
              justify-center
              group-hover:scale-110
              active:scale-95
            `}
          >
            <span 
              className={`
                text-sm 
                transform 
                transition-all 
                duration-300
                ${isDarkMode 
                  ? 'rotate-180 text-gray-700' 
                  : 'rotate-0 text-yellow-500'}
              `}
            >
              {isDarkMode ? '🌈' : '☀️'}
            </span>
          </div>
        </button>
      </div>

      {/* Page Title Section - Centered */}
      <div className='page-title-section absolute left-1/2 transform -translate-x-1/2'>
        <div className='page-title-box 
          bg-white/20
          backdrop-blur-md
          rounded-xl 
          px-6 
          py-2 
          relative
          border
          border-white/30
        '>
          <h2 className={`
            text-sm 
            font-medium 
            text-gray-800
            text-center 
            tracking-wider 
            uppercase
            font-sans
          `}>
            {currentPageTitle}
          </h2>
        </div>
      </div>

      {/* Profile and Logout Section - Right side */}
      <div className='profile-section flex items-center space-x-4 z-20 absolute right-0'>
        {/* Ticket Counts */}
        {showHeaderContent && (
          <div className='ticket-count-container flex space-x-2'>
            <div 
              className={`stats-box 
                flex 
                flex-col 
                items-center 
                justify-center 
                p-2 
                rounded-lg 
                bg-white 
                bg-opacity-10
                backdrop-blur-md
                border 
                border-gray-100
                border-opacity-20
                transition-all
                duration-300
                hover:scale-105
                hover:shadow-md
                relative
                z-10
                ${ticketsDoneToday > 0 ? 'border-green-200' : 'border-transparent'}
              `}
            >
              <span className='text-xs font-bold text-green-600 opacity-90'>
                {ticketsDoneToday}
              </span>
              <span className='text-xs text-gray-600 opacity-80'>Completed</span>
            </div>
            <div 
              className={`stats-box 
                flex 
                flex-col 
                items-center 
                justify-center 
                p-2 
                rounded-lg 
                bg-white 
                bg-opacity-10
                backdrop-blur-md
                border 
                border-gray-100
                border-opacity-20
                transition-all
                duration-300
                hover:scale-105
                hover:shadow-md
                relative
                z-10
                ${ticketsEnteredToday > 0 ? 'border-yellow-200' : 'border-transparent'}
              `}
            >
              <span className='text-xs font-bold text-yellow-600 opacity-90'>
                {ticketsEnteredToday}
              </span>
              <span className='text-xs text-gray-600 opacity-80'>New Tickets</span>
            </div>
          </div>
        )}

        {/* User Profile and Logout */}
        <div 
          className='relative group'
        >
          <div 
            className='flex items-center space-x-2'
          >
            <div 
              className={`
                relative
                flex 
                items-center 
                justify-center
                bg-white 
                bg-opacity-15 
                backdrop-blur-md
                rounded-xl 
                px-3 
                py-2
                border
                border-gray-100
                border-opacity-10
                transition-all 
                duration-400 
                ease-soft-spring
                transform 
                hover:scale-[1.05]
                z-10
                ${isDarkMode 
                  ? 'bg-[#55d0db]/20 border-[#55d0db]/20 shadow-[0_3px_8px_rgba(85,208,219,0.2)] hover:shadow-[0_6px_15px_rgba(85,208,219,0.3)] hover:bg-[#55d0db]/30' 
                  : 'bg-[#55d0db]/30 border-[#55d0db]/50 shadow-[0_3px_8px_rgba(85,208,219,0.1)] hover:shadow-[0_6px_15px_rgba(85,208,219,0.2)] hover:bg-[#55d0db]/50'}
                active:scale-[0.97]
                active:shadow-md
              `}
            >
              <FontAwesomeIcon 
                icon={faUserCircle} 
                className={`
                  text-2xl 
                  ${isDarkMode 
                    ? 'text-[#55d0db] opacity-80 group-hover:text-[#55d0db]' 
                    : 'text-[#55d0db] opacity-90 group-hover:text-[#55d0db]'}
                  hover:opacity-100 
                  transition-all
                `}
              />
            </div>

            {/* Tooltip */}
            <div 
              className={`
                absolute 
                right-full 
                top-1/2 
                transform 
                -translate-y-1/2 
                mr-2 
                px-3 
                py-1 
                text-xs 
                rounded-md 
                whitespace-nowrap
                transition-all 
                duration-300 
                opacity-0 
                group-hover:opacity-100 
                scale-90 
                group-hover:scale-100 
                pointer-events-none
                z-50
                ${isDarkMode 
                  ? 'bg-gray-700 text-gray-200 shadow-lg' 
                  : 'bg-gray-800 text-white shadow-md'}
              `}
            >
              {localStorage.getItem("email")}
            </div>
          </div>
        </div>

        <button
          onClick={handleLogout}
          className={`
            flex 
            items-center 
            justify-center
            w-9 
            h-9 
            rounded-xl
            transition-all 
            duration-400 
            ease-soft-spring
            transform 
            hover:scale-[1.1]
            ${isDarkMode 
              ? 'bg-red-900/50 text-red-300 hover:text-red-500 shadow-[0_3px_8px_rgba(150,20,20,0.2)] hover:shadow-[0_6px_15px_rgba(150,20,20,0.3)] hover:bg-red-900/70' 
              : 'bg-red-100/70 text-red-500 hover:text-red-700 shadow-[0_3px_8px_rgba(220,50,50,0.1)] hover:shadow-[0_6px_15px_rgba(220,50,50,0.2)] hover:bg-red-200/80'}
            relative
            before:absolute 
            before:inset-0 
            before:bg-gradient-to-br 
            before:from-white/10 
            before:to-transparent 
            before:rounded-xl 
            before:opacity-0 
            hover:before:opacity-100 
            before:transition-opacity 
            before:duration-300
            active:scale-90
            focus:outline-none
          `}
          title="Logout"
        >
          <FontAwesomeIcon 
            icon={faSignOutAlt} 
            className={`
              ${isDarkMode 
                ? 'text-red-500 opacity-80' 
                : 'text-red-600 opacity-90'}
              hover:opacity-100 
              transition-opacity
            `}
          />
        </button>
      </div>
    </div>
  );
};

export default Header;
