import React, { useState, useEffect, useMemo } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaFilter, FaSearch } from "react-icons/fa";
import LogDetailsModal from "./LogsDetails";
import Axios from "../../../service/Axios";
import { FEATURE_OPTION_LISTS } from "./constant";

const LogsTable = () => {
  const limit = 10;
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedLog, setSelectedLog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Filter states
  const [filters, setFilters] = useState({
    searchTerm: '',
    featureType: '',
    transactionId: '',
    phoneNumber: '',
    startDate: '',
    endDate: ''
  });

  useEffect(() => {
    fetchData();
  }, [page, filters]);

  const fetchData = () => {
    const { featureType, transactionId, phoneNumber, startDate, endDate } = filters;
    const url = `/v1/logsList?page=${page}&limit=${limit}&feature_type=${featureType}&transaction_id=${transactionId}&phone_number=${phoneNumber}&start_date=${startDate}&end_date=${endDate}`;
    
    Axios.get(url)
      .then((response) => {
        const data = response.data;
        if (Array.isArray(data.data) && data.data.length > 0) {
          setLogs(data.data);
          setTotalPages(data.pagination.totalPage);
        } else {
          setLogs([]);
          setTotalPages(1);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  // Advanced filtering with search
  const filteredLogs = useMemo(() => {
    return logs.filter((log) => 
      Object.values(log).some((value) => 
        value.toString().toLowerCase().includes(filters.searchTerm.toLowerCase())
      )
    );
  }, [logs, filters.searchTerm]);

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const openModal = (log) => {
    setSelectedLog(log);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateFilter = (key, value) => {
    setFilters(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const clearAllFilters = () => {
    setFilters({
      searchTerm: '',
      featureType: '',
      transactionId: '',
      phoneNumber: '',
      startDate: '',
      endDate: ''
    });
  };

  return (
    <div className="container mx-auto px-2 sm:px-4 md:px-6 lg:px-2 py-2 bg-gray-50">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        {/* Header with Search and Filters */}
        <div className="px-4 sm:px-6 md:px-12 py-4 border-b border-gray-200 flex items-center justify-between space-x-4">
          {/* Search Input */}
          <div className="relative w-full max-w-md">
            <input
              type="text"
              placeholder="Search logs..."
              value={filters.searchTerm}
              onChange={(e) => updateFilter('searchTerm', e.target.value)}
              className="w-full pl-8 pr-4 py-2 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>

          {/* Compact Filters Dropdown */}
          <div className="relative group">
            <button 
              className="p-2 bg-blue-50 text-blue-600 rounded-full hover:bg-blue-100 transition-colors"
              onClick={() => updateFilter('featureType', filters.featureType ? '' : FEATURE_OPTION_LISTS[0].value)}
            >
              <FaFilter />
            </button>
            
            {filters.featureType && (
              <div className="absolute right-0 mt-2 w-64 sm:w-80 bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                  {/* Feature Type Filter */}
                  <select
                    value={filters.featureType}
                    onChange={(e) => updateFilter('featureType', e.target.value)}
                    className="col-span-full px-2 py-1 border border-gray-300 rounded-md text-sm"
                  >
                    {FEATURE_OPTION_LISTS.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  {/* Transaction ID */}
                  <input
                    type="text"
                    placeholder="Transaction ID"
                    value={filters.transactionId}
                    onChange={(e) => updateFilter('transactionId', e.target.value)}
                    className="px-2 py-1 border border-gray-300 rounded-md text-sm"
                  />

                  {/* Phone Number */}
                  <input
                    type="text"
                    placeholder="Phone Number"
                    value={filters.phoneNumber}
                    onChange={(e) => updateFilter('phoneNumber', e.target.value)}
                    className="px-2 py-1 border border-gray-300 rounded-md text-sm"
                  />

                  {/* Date Range */}
                  <input
                    type="date"
                    value={filters.startDate}
                    onChange={(e) => updateFilter('startDate', e.target.value)}
                    className="px-2 py-1 border border-gray-300 rounded-md text-sm"
                  />
                  <input
                    type="date"
                    value={filters.endDate}
                    onChange={(e) => updateFilter('endDate', e.target.value)}
                    className="px-2 py-1 border border-gray-300 rounded-md text-sm"
                  />

                  {/* Clear and Apply Buttons */}
                  <div className="col-span-full flex justify-end space-x-2 mt-2">
                    <button 
                      onClick={clearAllFilters}
                      className="px-2 py-1 bg-gray-100 text-gray-700 rounded-md text-xs hover:bg-gray-200"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Logs Table */}
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-100 border-b">
              <tr>
                {['Transaction ID', 'Phone Number', 'Status', 'Feature Type', 'Updated At', 'Updated By'].map((header) => (
                  <th 
                    key={header} 
                    className="px-2 sm:px-4 py-3 text-xs font-semibold text-gray-600 uppercase text-left truncate"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredLogs.length > 0 ? (
                filteredLogs.map((log, index) => (
                  <tr
                    key={log.related_table_key + index}
                    onClick={() => openModal(log)}
                    className="hover:bg-gray-50 cursor-pointer"
                  >
                    {[
                      log.transaction_id,
                      log.phone_number,
                      log.status,
                      log.feature_type,
                      log.updated_at,
                      log.updated_by
                    ].map((value, colIndex) => (
                      <td 
                        key={colIndex} 
                        className="px-2 sm:px-4 py-3 text-xs sm:text-sm text-gray-700 truncate max-w-[150px]"
                      >
                        {value || 'N/A'}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center py-6 text-gray-500">
                    No logs found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex flex-col sm:flex-row justify-between items-center px-4 sm:px-6 md:px-12 py-4 border-t border-gray-200 space-y-2 sm:space-y-0">
          <div className="text-xs sm:text-sm text-gray-600">
            Page {page} of {totalPages}
          </div>
          <div className="flex space-x-2">
            <button
              onClick={handlePrevPage}
              disabled={page === 1}
              className="px-3 py-1 bg-blue-50 text-blue-600 rounded-md disabled:opacity-50 hover:bg-blue-100 transition-colors flex items-center text-xs sm:text-sm"
            >
              <IoIosArrowBack className="mr-1" /> Prev
            </button>
            <button
              onClick={handleNextPage}
              disabled={page === totalPages}
              className="px-3 py-1 bg-blue-50 text-blue-600 rounded-md disabled:opacity-50 hover:bg-blue-100 transition-colors flex items-center text-xs sm:text-sm"
            >
              Next <IoIosArrowForward className="ml-1" />
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && selectedLog && (
        <LogDetailsModal log={selectedLog} closeModal={closeModal} />
      )}
    </div>
  );
};

export default LogsTable;
