import React, { useState, useEffect } from "react";
import Axios from "../../../service/Axios";
import NotificationAlert from "../../../components/Alert/NotificationAlert";

function ChangeEmail() {
  const [newEmailMember, setNewEmailMember] = useState("");
  const [searchedUser, setSearchedUser] = useState("+62");
  const [isLocked, setIsLocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });
  const [phone, setPhone] = useState("+62");
  const [userFound, setUserFound] = useState(false);

  const changeUserName = async (e) => {
    e.preventDefault();
    if (!newEmailMember) {
      setShowAlert({
        type: "error",
        message: "Please input a new email for the member",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await Axios.put(
        "/v1/change-email",
        {
          phoneNumber: phone,
          newUserEmail: newEmailMember,
        }
      );

      if (response.status === 200) {
        setShowAlert({
          type: "success",
          message: "Email changed successfully.",
        });
        setNewEmailMember("");
        setSearchedUser("+62");
      } else {
        setShowAlert({
          type: "error",
          message: "User not found.",
        });
        setUserFound(false);
      }
    } catch (error) {
      setShowAlert({
        type: "error",
        message: "An error occurred. Please try again later.",
      });
      setUserFound(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShowAlert({ type: "", message: "", data: "" });

    try {
      const url = `/v1/users/query/${searchedUser}`;
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { phone, email } = response.data;
        setPhone(phone);
        setShowAlert({
          type: "success",
          message: "User Found.",
          data: email,
        });
        setUserFound(true);
      } else {
        setShowAlert({
          type: "error",
          message: "User not found.",
        });
        setUserFound(false);
      }
    } catch (error) {
      setUserFound(false);
      setShowAlert({
        type: "error",
        message: "User not found.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnlock = () => {
    setIsLocked(false);
  };

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {
        setShowAlert({ type: "", message: "", data: "" });
      }, 15000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  return (
    <div className="container mx-auto px-4">
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className="w-full max-w-2xl mx-auto bg-gradient-to-br from-white via-gray-50 to-gray-100 shadow-2xl rounded-xl border border-gray-200 p-8 mt-0 mb-56 transition-all duration-300 hover:shadow-3xl hover:scale-[1.01]">
        {/* Phone Member Input */}
        <div className="mb-4">
          <label 
            htmlFor="phone" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Phone Number
          </label>
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
            <input
              type="text"
              id="phone"
              className="flex-grow px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
              placeholder="+62 Phone Number"
              value={searchedUser}
              onChange={(e) => setSearchedUser(e.target.value)}
              disabled={isLocked || isSubmitting}
            />
            <button
              onClick={userFound && isLocked ? handleUnlock : handleSearch}
              className={`w-full sm:w-auto px-6 py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isSubmitting}
            >
              {isLocked ? "Unlock" : "Search"}
            </button>
          </div>
        </div>

        {/* New Email Member Input */}
        <div className="mb-4">
          <label 
            htmlFor="newEmailMember" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            New Email
          </label>
          <input
            type="text"
            id="newEmailMember"
            value={newEmailMember}
            onChange={(e) => setNewEmailMember(e.target.value)}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
            placeholder="Enter new email"
          />
        </div>

        <button
          onClick={changeUserName}
          className={`w-full py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
            (!newEmailMember || isSubmitting) ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={!newEmailMember || isSubmitting}
        >
          {isSubmitting ? "Changing..." : "Change Email"}
        </button>
      </div>
    </div>
  );
}

export default ChangeEmail;
