import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiCalendar } from "react-icons/bi";
import "../change-expired-date/DatePickerCustomStyles.css";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import moment from "moment";
import Axios from "../../../service/Axios";

const ChangeExpiredDate = () => {
  const [searchedUser, setSearchedUser] = useState("+62");
  const [phone, setPhone] = useState("+62");
  const [existingExpires, setExpires] = useState("");
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });
  const [newExpiredDate, setNewExpiredDate] = useState("");
  const [newLogs, setNewLogs] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userFound, setUserFound] = useState(false);

  const handleSearch = async () => {
    setShowAlert({ type: "", message: "", data: "" });

    try {
      const url = `/v1/users/query/${searchedUser}`;
      const response = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const { phone, name, expiredMembership } = response.data;
        setPhone(phone);
        setExpires(moment(expiredMembership).toDate());
        setShowAlert({
          type: "success",
          message: "User Found.",
          data: `${name} [ Membership expires on: ${moment(
            expiredMembership
          ).format("YYYY-MM-DD")} ]`,
        });
        setIsLocked(!isLocked);
        setUserFound(true);
      } else {
        setShowAlert({ type: "error", message: "User not found." });
        setUserFound(false);
      }
    } catch (error) {
      setShowAlert({ type: "error", message: "User not found." });
      setUserFound(false);
    }
  };

  const handleUnlock = () => {
    setIsLocked(false);
  };

  const handleExpiredDateChange = async (e) => {
    e.preventDefault();
    if (!newExpiredDate) {
      setShowAlert({
        type: "error",
        message: "Please select a new expiration date.",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      if (userFound) {
        const formattedDate = moment(newExpiredDate).format("YYYY-MM-DD");

        const response = await Axios.put(
          `/v1/update-expiration/${phone}`,
          {
            newExpirationDate: formattedDate,
            newLogs: newLogs,
          }
        );

        if (response.status === 200) {
          setShowAlert({
            type: "success",
            message: "Expired Date updated successfully.",
          });
          setNewExpiredDate("");
          setNewLogs("");
          setSearchedUser("+62");
          setIsLocked(false);
        } else {
          setShowAlert({ type: "error", message: "Something went wrong." });
        }
      } else {
        setShowAlert({ type: "error", message: "User not found." });
        setUserFound(false);
      }
    } catch (error) {
      setShowAlert({
        type: "error",
        message: "An error occurred. Please try again later.",
      });
      setUserFound(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {
        setShowAlert({ type: "", message: "" });
      }, 15000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert.message]);

  return (
    <div className="container mx-auto px-8 mt-4">
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg border border-gray-100 p-8 mt-0 mb-36">
        {/* Phone Member Input */}
        <div className="mb-4">
          <label 
            htmlFor="phone" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Phone Number
          </label>
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
            <input
              type="text"
              id="phone"
              className="flex-grow px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
              placeholder="+62 Phone Number"
              value={searchedUser}
              onChange={(e) => setSearchedUser(e.target.value)}
              disabled={isLocked || isSubmitting}
            />
            <button
              onClick={userFound && isLocked ? handleUnlock : handleSearch}
              className={`w-full sm:w-auto px-6 py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
                isSubmitting ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isSubmitting}
            >
              {isLocked ? "Unlock" : "Search"}
            </button>
          </div>
        </div>

        {/* New Expired Date Input */}
        <div className="mb-4">
          <label 
            htmlFor="newExpiredDate" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            New Expired Date
          </label>
          <div className="relative">
            <DatePicker
              selected={newExpiredDate}
              onChange={(date) => setNewExpiredDate(date)}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
              dateFormat='yyyy-MM-dd'
              placeholderText="Select Expired Date"
              withPortal
              showYearDropdown
              yearDropdownItemNumber={10}
              customInput={
                <input
                  type='text'
                  id='newExpiredDate'
                  value={
                    newExpiredDate
                      ? moment(newExpiredDate).format("YYYY-MM-DD")
                      : ""
                  }
                  readOnly
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300 cursor-pointer"
                />
              }
            />
            <BiCalendar
              className='absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-950 cursor-pointer'
              onClick={() => setShowDatePicker(true)}
            />
          </div>
        </div>

        {/* Logs Input */}
        <div className="mb-4">
          <label 
            htmlFor="newLogs" 
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Logs Membership
          </label>
          <textarea
            id="newLogs"
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
            placeholder="Enter any additional logs or notes"
            value={newLogs}
            onChange={(e) => setNewLogs(e.target.value)}
            rows="3"
            disabled={isSubmitting}
          />
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            type="submit"
            onClick={handleExpiredDateChange}
            className={`w-full px-6 py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
              isSubmitting || !userFound ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isSubmitting || !userFound}
          >
            {isSubmitting ? "Updating..." : "Update Expired Date"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeExpiredDate;
