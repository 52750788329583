import React, { useState, useEffect } from "react";
import NotificationAlert from "../../../components/Alert/NotificationAlert";
import Select from "react-select";
import Axios from "../../../service/Axios";
import { useTheme } from "../../../context/ThemeContext";

function ChangePackages() {
  const [type, setType] = useState(null);
  const [packageOptions, setPackageOptions] = useState([]);
  const [transactionID, setTransactionID] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [packages, setPackages] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState({
    type: "",
    message: "",
    data: "",
  });
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const fetchPackageList = async () => {
      try {
        const response = await Axios.get("/v1/package-membership-list");
        const packages = response.data.map((packageMembership) => ({
          value: packageMembership.packageName,
          label: packageMembership.packageName,
        }));
        setPackageOptions(packages);
      } catch (error) {
        console.error("Error fetching package list:", error);
      }
    };

    fetchPackageList();
  }, []);

  useEffect(() => {
    if (showAlert.message) {
      const timer = setTimeout(() => {
        setShowAlert({ type: "", message: "", data: "" });
      }, 15000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  const resetForm = () => {
    setType(null);
    setTransactionID("");
    setPackages(null);
    setPromoCode("");
    setNewPrice("");
  };

  const validateForm = () => {
    const isChangePackagePrice = type?.value === 'Change Package Price';
    const isChangePackagePriceWithPromoCode = type?.value === 'Change Package Price With Promo Code';
    const isChangePackagePlan = type?.value === 'Change Package Plan';
    const isChangePackagePlanWithPromoCode = type?.value === 'Change Package Plan With Promo Code';

    const isValid = (isChangePackagePrice || isChangePackagePriceWithPromoCode) 
      ? (transactionID && newPrice && (isChangePackagePriceWithPromoCode ? promoCode : true)) 
      : (isChangePackagePlan || isChangePackagePlanWithPromoCode) 
        ? (type?.value && transactionID && packages?.value && newPrice) 
        : false;

    if (!isValid) {
      setShowAlert({
        type: "error",
        message: "Please fill in all required fields",
      });
    }
    return isValid;
  };

  const buildPayload = () => ({
    type_change: type?.value,
    transaction_id: transactionID,
    promo_code: promoCode,
    new_package_name: packages?.value,
    new_package_price: newPrice,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (!validateForm()) {
        return;
      }

      const response = await Axios.put("/v1/change-packages", buildPayload());

      if (response.status === 200) {
        setShowAlert({
          type: "success",
          message: "Data updated successfully.",
        });
        resetForm();
      } else {
        throw new Error(response?.data?.error || "Failed to update data.");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error 
        || (error.request ? "No response received from server." 
        : error.message || "An error occurred.");

      setShowAlert({
        type: "error",
        message: errorMessage,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFormFields = () => {
    switch (type?.value) {
      case "Change Package Price":
        return (
          <>
            <div className="mb-4">
              <label
                htmlFor="transactionID"
                className="block text-sm font-medium text-gray-950 mb-2"
              >
                Transaction ID <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="transactionID"
                value={transactionID}
                onChange={(e) => setTransactionID(e.target.value)}
                className="block w-full p-2 pl-10 text-sm text-gray-950 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:border-gray-500"
                placeholder="Enter Transaction ID"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="newPrice"
                className="block text-sm font-medium text-gray-950 mb-2"
              >
                New Price <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="newPrice"
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
                className="block w-full p-2 pl-10 text-sm text-gray-950 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:border-gray-500"
                placeholder="Enter New Price"
              />
            </div>
          </>
        );
      case "Change Package Price With Promo Code":
        return (
          <>
            <div className="mb-4">
              <label
                htmlFor="transactionID"
                className="block text-sm font-medium text-gray-950 mb-2"
              >
                Transaction ID <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="transactionID"
                value={transactionID}
                onChange={(e) => setTransactionID(e.target.value)}
                className="block w-full p-2 pl-10 text-sm text-gray-950 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:border-gray-500"
                placeholder="Enter Transaction ID"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="promoCode"
                className="block text-sm font-medium text-gray-950 mb-2"
              >
                Promo Code <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="promoCode"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                className="block w-full p-2 pl-10 text-sm text-gray-950 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:border-gray-500"
                placeholder="Enter Promo Code"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="newPrice"
                className="block text-sm font-medium text-gray-950 mb-2"
              >
                New Price <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="newPrice"
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
                className="block w-full p-2 pl-10 text-sm text-gray-950 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:border-gray-500"
                placeholder="Enter New Price"
              />
            </div>
          </>
        );
      case "Change Package Plan":
        return (
          <>
            <div className="mb-4">
              <label
                htmlFor="transactionID"
                className="block text-sm font-medium text-gray-950 mb-2"
              >
                Transaction ID <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="transactionID"
                value={transactionID}
                onChange={(e) => setTransactionID(e.target.value)}
                className="block w-full p-2 pl-10 text-sm text-gray-950 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:border-gray-500"
                placeholder="Enter Transaction ID"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="newPrice"
                className="block text-sm font-medium text-gray-950 mb-2"
              >
                New Price <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="newPrice"
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
                className="block w-full p-2 pl-10 text-sm text-gray-950 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:border-gray-500"
                placeholder="Enter New Price"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="packages"
                className="block text-sm font-medium text-gray-950 mb-2"
              >
                New Package Name <span className="text-red-500">*</span>
              </label>
              <div className="mb-6">
                <Select
                  id="packages"
                  value={packages}
                  onChange={(selectedOption) => setPackages(selectedOption)}
                  options={packageOptions}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      padding: "8px",
                      borderRadius: "0.5rem",
                      borderColor: "#D1D5DB", // Tailwind gray-300
                      boxShadow: "none",
                      "&:hover": {
                        borderColor: "#3B82F6", // Tailwind blue-500
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#3B82F6" : "white",
                      color: state.isSelected ? "white" : "black",
                      "&:hover": {
                        backgroundColor: state.isSelected ? "#3B82F6" : "#F3F4F6", // Tailwind gray-100
                      },
                    }),
                  }}
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
            </div>
          </>
        );
      case "Change Package Plan With Promo Code":
        return (
          <>
            <div className="mb-4 mt-4">
              <label
                htmlFor="transactionID"
                className="block text-sm font-medium text-gray-950 mb-2"
              >
                Transaction ID <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="transactionID"
                value={transactionID}
                onChange={(e) => setTransactionID(e.target.value)}
                className="block w-full p-2 pl-10 text-sm text-gray-950 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:border-gray-500"
                placeholder="Enter Transaction ID"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="promoCode"
                className="block text-sm font-medium text-gray-950 mb-2"
              >
                Promo Code
              </label>
              <input
                type="text"
                id="promoCode"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                className="block w-full p-2 pl-10 text-sm text-gray-950 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:border-gray-500"
                placeholder="Enter Promo Code"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="newPrice"
                className="block text-sm font-medium text-gray-950 mb-2"
              >
                New Price <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="newPrice"
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
                className="block w-full p-2 pl-10 text-sm text-gray-950 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:border-gray-500"
                placeholder="Enter New Price"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="packages"
                className="block text-sm font-medium text-gray-950 mb-2"
              >
                New Package Name <span className="text-red-500">*</span>
              </label>
              <div className="mb-6">
                <Select
                  id="packages"
                  value={packages}
                  onChange={(selectedOption) => setPackages(selectedOption)}
                  options={packageOptions}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      padding: "8px",
                      borderRadius: "0.5rem",
                      borderColor: "#D1D5DB", // Tailwind gray-300
                      boxShadow: "none",
                      "&:hover": {
                        borderColor: "#3B82F6", // Tailwind blue-500
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#3B82F6" : "white",
                      color: state.isSelected ? "white" : "black",
                      "&:hover": {
                        backgroundColor: state.isSelected ? "#3B82F6" : "#F3F4F6", // Tailwind gray-100
                      },
                    }),
                  }}
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-8 mt-40">
      {showAlert.message && (
        <NotificationAlert
          type={showAlert.type}
          message={showAlert.message}
          data={showAlert.data}
        />
      )}
      <div className="w-full max-w-2xl mx-auto bg-white shadow-lg rounded-lg border border-gray-100 p-8 mt-0 mb-36">
        <div className="mb-4">
          <label
            htmlFor="type"
            className="block text-sm font-medium text-gray-950 mb-2"
          >
            Type <span className="text-red-500">*</span>
          </label>
          <div className="mb-6">
            <Select
              id="type"
              value={type}
              onChange={(selectedOption) => setType(selectedOption)}
              options={[
                { value: "Change Package Price", label: "Change Package Price" },
                {
                  value: "Change Package Price With Promo Code",
                  label: "Change Package Price With Promo Code",
                },
                { value: "Change Package Plan", label: "Change Package Plan" },
                {
                  value: "Change Package Plan With Promo Code",
                  label: "Change Package Plan With Promo Code",
                },
              ]}
              styles={{
                control: (provided) => ({
                  ...provided,
                  padding: "8px",
                  borderRadius: "0.5rem",
                  borderColor: "#D1D5DB", // Tailwind gray-300
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#3B82F6", // Tailwind blue-500
                  },
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#3B82F6" : "white",
                  color: state.isSelected ? "white" : "black",
                  "&:hover": {
                    backgroundColor: state.isSelected ? "#3B82F6" : "#F3F4F6", // Tailwind gray-100
                  },
                }),
              }}
              isSearchable={true}
              isClearable={true}
            />
          </div>
        </div>

        {renderFormFields()}

        <div className="mt-0">
          <button
            onClick={handleSubmit}
            className={`w-full px-6 py-3 bg-slate-600 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500/50 transition-all duration-300 ${
              (!type || !transactionID || isSubmitting) 
                ? "opacity-50 cursor-not-allowed" 
                : ""
            }`}
            disabled={!type || !transactionID || isSubmitting}
          >
            {isSubmitting ? "Updating..." : "Update Package"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePackages;
